import styles from './Company.module.css';

import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import missao from './images/missao.jpg';
import visao from './images/visao.jpg';
import valores from './images/valores.jpg';
import fios from './images/fios.jpg';
import camera from './images/camera.jpg';
import portaria from './images/portaria.jpg';
import whats from './images/whats.jpg';

const Company = () => {

    const [stick, setStick] = useState(false);

    const handleScroll = () => {

        if (window.scrollY > 5) {
            setStick(true);
        } else {
            setStick(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [imagesClass, setImagesClass] = useState(styles.automatic);
    const [activeButton, setActiveButton] = useState(0);

    const handleDotOne = () => {
        setImagesClass(styles.manualone);
        setActiveButton(1);
    }

    const handleDotTwo = () => {
        setImagesClass(styles.manualtwo);
        setActiveButton(2);
    }

    const handleDotThree = () => {
        setImagesClass(styles.manualthree);
        setActiveButton(3);
    }

    return (
        <div>
            <NavBar stick={stick} header={true}/>
            {stick &&
                <div>
                    <a href='https://api.whatsapp.com/send?phone=5511961444586' className={styles.whats}>
                        <img src={whats} alt='WhatsApp' />
                    </a>
                </div>
            }
            <div className={styles.images}>
                <img src={missao}
                    className={imagesClass}
                    alt="Nossa missão é prover soluções alinhadas 
                    a evolução da tecnologia que permitam superar a expectativa de nossos clientes,
                    conquistando sempre sua confiança com agilidade na entrega."
                />
                <img src={visao}
                    alt="Temos como visão ser reconhecida como a melhor
                    empresa no ramo de soluções de segurança residencial
                    e empresarial, agregando qualidade e eficiência na prestação de serviços."
                />
                <img src={valores}
                    alt="Nossos valores são: 
                    Valorizar e respeitar os nossos colaboradores, clientes e parceiros.
                    Excelência no atendimento ao cliente.
                    O cliente é a razão do nosso negócio.
                    Agilidade na entrega.
                    Foco em Resultado.
                    Ética Profissional.
                    Ser transparente, honesto e agir com integridade."
                />
            </div>
            <div className={styles.dots}>
                <button className={
                    activeButton === 0 ? styles.dotone :
                        activeButton === 1 ? styles.dotactive : styles.dotunactive
                }
                    onClick={handleDotOne} />
                <button className={
                    activeButton === 0 ? styles.dottwo :
                        activeButton === 2 ? styles.dotactive : styles.dotunactive
                }
                    onClick={handleDotTwo} />
                <button className={
                    activeButton === 0 ? styles.dotthree :
                        activeButton === 3 ? styles.dotactive : styles.dotunactive
                }
                    onClick={handleDotThree} />
            </div>
            <div className={styles.text}>
                <div className={styles.lefttext}>
                    <h1>Sobre a Empresa</h1>
                    <div className={styles.textcontainer}>
                        <h2>A FCPServices é uma empresa criada para atuar na área de TI com prestação
                            de serviços as pessoas físicas e jurídicas.
                            Nosso <NavLink to='/servicos' className={styles.links}>catálogo</NavLink> <span> </span>
                            de serviços foi elaborado para atender a necessidade de todos os nossos clientes,
                            atuando de forma a centralizar todos os diversos contratos de serviços
                            em uma única empresa, propondo um canal de comunicação direto entre empresa contratante
                            contratada.
                        </h2>
                    </div>
                </div>
                <div className={styles.image}>
                    <img src={fios} alt="" />
                </div>
            </div>
            <div className={styles.text}>
                <div className={styles.imageleft}>
                    <img src={camera} alt="" />
                </div>
                <div className={styles.lefttext}>
                    <h1>Nosso foco</h1>
                    <div className={styles.textcontainer}>
                        <h2>
                            A FCPServices tem como foco principal integrar soluções que atendam a
                            necessidade das empresas sempre com soluções inovadoras em tecnologia,
                            sendo elas tanto na área de segurança, redes, infraestrutura, voz, comunicação
                            e proteção Elétrica (Nobreak).
                        </h2>
                    </div>
                </div>
            </div>
            <div className={styles.text}>
                <div className={styles.lefttext}>
                    <h1>Vantagens FCP</h1>
                    <div className={styles.textcontainer}>
                        <h2>
                        <p></p>
                        Menos burocracia e preocupação para quem necessita da prestação de serviço
                        e mais agilidade para o seu negócio!
                        <p></p>
                        Executamos projetos de acordo com a sua necessidade.
                        </h2>
                    </div>
                </div>
                <div className={styles.image}>
                    <img src={portaria} alt="" />
                </div>
            </div>
            <Footer className={styles.footer} />
        </div >
    )
}

export default Company;