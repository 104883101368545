import styles from './Services.module.css';

import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import NavBar from '../components/NavBar.js';
import Footer from '../components/Footer.js';

import porteiro from './images/porteiro.jpg';
import modulo from './images/modulo.jpg';
import cftv from './images/cftv.jpg';
import cameracel from './images/cameracel.jpg';
import pabx from './images/pabx.jpg';
import nobreak from './images/nobreak.jpg';
import estab from './images/estab.jpg';
import video from './images/video.jpg';
import redes from './images/redes.jpg';
import cabos from './images/cabos.jpg';
import cabosd from './images/cabosd.jpg';
import whats from './images/whats.jpg';
import site from './images/site.jpg';

const Services = () => {

    const [stick, setStick] = useState(false);

    const [seeMoreFirst, setSeeMoreFirst] = useState(false);
    const [seeMoreSecond, setSeeMoreSecond] = useState(false);
    const [seeMoreThird, setSeeMoreThird] = useState(false);
    const [seeMoreFourth, setSeeMoreFourth] = useState(false);

    const handleScroll = () => {

        if (window.scrollY > 5) {
            setStick(true);
        } else {
            setStick(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSeeMoreFirst = () => {
        setSeeMoreFirst(true);
    }

    const handleSeeLessFirst = () => {
        setSeeMoreFirst(false);
    }

    const handleSeeMoreSecond = () => {
        setSeeMoreSecond(true);
    }

    const handleSeeLessSecond = () => {
        setSeeMoreSecond(false);
    }

    const handleSeeMoreThird = () => {
        setSeeMoreThird(true);
    }

    const handleSeeLessThird = () => {
        setSeeMoreThird(false);
    }

    const handleSeeMoreFourth = () => {
        setSeeMoreFourth(true);
    }

    const handleSeeLessFourth = () => {
        setSeeMoreFourth(false);
    }

    return (
        <div className={styles.container}>
            <NavBar stick={stick} header={true}/>
            {stick &&
                <div>
                    <a href='https://api.whatsapp.com/send?phone=5511961444586' className={styles.whats}>
                        <img src={whats} alt='WhatsApp' />
                    </a>
                </div>
            }
            <div className={styles.textcontainer}>
                <h1>
                    Inovação e excelência para oferecer ampla diversidade de
                    soluções e serviços unificados em uma única empresa
                </h1>
            </div>

            <div className={styles.services}>
                <div className={styles.text}>
                    <h2>Porteiro Eletrônico</h2>
                    <h3>
                        Quem tem algum imóvel ou trabalha em um, sabe a importância de se garantir
                        a segurança do mesmo. No Brasil as casas, apartamentos e condomínios
                        costumam ser fechados. Isso serve para dificultar o acesso a esses imóveis e,
                        desse modo, protegê-lo de eventuais criminosos e visitas indesejadas.
                        A instalação de porteiro eletrônico é uma excelente forma de garantir a segurança do seu patrimônio.
                    </h3>
                    {seeMoreFirst === false &&
                        <button className={styles.more}
                            onClick={handleSeeMoreFirst}
                            title='Ver mais'>
                            +
                        </button>
                    }
                    {seeMoreFirst &&
                        <h3>
                            Para que se possa aproveitar o máximo dessa experiência, é bom que se chame
                            um técnico ao sinal de qualquer chiado, mau contato, e qualquer problema que
                            o aparelho possa apresentar.
                        </h3>
                    }
                    {seeMoreFirst &&
                        <h3>
                            Os aparelhos de porteiro eletrônicos mais modernos agora possuem também outros mecanismos
                            auxiliares, como câmera de vídeo e sensores. Porém, independente do modelo, sendo ele mais
                            simples ou mais sofisticado, é preciso fazer uma boa pesquisa dos modelos disponíveis e
                            acompanhar todo o processo de instalação do mesmo, garantindo assim o sucesso da aquisição.
                        </h3>
                    }
                    {seeMoreFirst &&
                        <button className={styles.less}
                            onClick={handleSeeLessFirst}
                            title='Ver menos'>
                            -
                        </button>
                    }
                </div>
                <div className={styles.images}>
                    <img src={porteiro} alt="Porteiro eletrônico" />
                    {seeMoreFirst &&
                        <img src={modulo} alt="Porteiro intelbras lv 7000" className={styles.imgtwo} />
                    }
                </div>
            </div>

            <div className={styles.services}>
                <div className={styles.text}>
                    <h2>Sistema de Câmeras CFTV</h2>
                    <h3>
                        Oferecer um sistema de CFTV a um cliente esta muito além de vender um sistema de alta
                        tecnologia, ao trabalhar com sistemas de CFTV DIGITAL estamos criando a possibilidade de
                        utilizar um sistema inteligente com recursos dinâmicos, oferecendo tempo de autonomia muito
                        maior. A qualidade da imagem digital é incomparavelmente superior além de não sofrer degradações
                        com armazenamento.
                    </h3>
                    {seeMoreSecond === false &&
                        <button className={styles.more}
                            onClick={handleSeeMoreSecond}
                            title='Ver mais'>
                            +
                        </button>
                    }
                    {seeMoreSecond &&
                        <h3>
                            Cada vez mais os benefícios do CFTV DIGITAL substituem a tecnologia
                            anteriormente dominante, por todas as suas vantagens, mas principalmente
                            pela possibilidade de conexão em rede, permitindo o acesso local ou remoto.
                        </h3>
                    }
                    {seeMoreSecond &&
                        <h3>
                            O circuito fechado de televisão (CFTV) consiste em um sistema de câmeras cuja as imagens
                            estarão disponíveis numa TV, através de um aparelho chamado DVR ou Stand Alone. As imagens
                            podem ser gravadas ou apenas exibidas, além disso podendo ser configurado para disparar alarme,
                            possibilitar acesso remoto na internet através de um computador ou aplicativo no celular que
                            podera ser acessado de qualquer lugar do mundo. Portanto, o DVR tem papel essencial e pode ser
                            configurado para diversas funções.
                        </h3>
                    }
                    {seeMoreSecond &&
                        <button className={styles.less}
                            onClick={handleSeeLessSecond}
                            title='Ver menos'>
                            -
                        </button>
                    }
                </div>
                <div className={styles.images}>
                    <img src={cftv} alt="Câmera CFTV Intelbras" />
                    {seeMoreSecond &&
                        <img src={cameracel} alt="Camera com conexão celular" className={styles.imgtwo} />
                    }
                </div>
            </div>

            <div className={styles.services}>
                <div className={styles.text}>
                    <h2>Instalação e Manutenção de PABX</h2>
                    <h3>
                        Temos uma equipe qualificada para lhe oferecer todo suporte técnico qualificado
                        na manutenção e instalação de Centrais PABX dos fabricantes Avaya, Intelbras e
                        Cisco, bem como tambem conserto de placas, switch e telefones das tecnoogias
                        analógico, digital e IP.
                    </h3>
                </div>
                <div className={styles.images}>
                    <img src={pabx} alt="Sistema PABX" className={styles.pabx} />
                </div>
            </div>

            <div className={styles.services}>
                <div className={styles.text}>
                    <h2>Manutenção de Nobreak e Estabilizadores</h2>
                    <h3>
                        Um nobreak  é um condicionador que regula e estabiliza a voltagem e a pureza da energia que
                        chega até os equipamentos eletrônicos conectados a ele. Além disso, nobreak também é responsáve
                        por alimentar os dispositivos, em caso de queda de luz, através de uma bateria.
                    </h3>
                    {seeMoreThird === false &&
                        <button className={styles.more}
                            onClick={handleSeeMoreThird}
                            title='Ver mais'>
                            +
                        </button>
                    }
                    {seeMoreThird &&
                        <h3>
                            Por que ter um nobreak? <p></p>
                            Tempestades, como estas que ocorrem no verão, geram uma enorme variação de energia, devido às
                            descargas elétricas de raios nas áreas próximas. Estas variações podem levar a queima total ou
                            parcial de equipamentos.
                            É também muito comum ocorrerem variações de até 10%, para cima ou para
                            baixo, na voltagem de redes elétricas, o que pode reduzir a vida útil
                            dos aparelhos.
                        </h3>
                    }
                    {seeMoreThird &&
                        <button className={styles.less}
                            onClick={handleSeeLessThird}
                            title='Ver menos'>
                            -
                        </button>
                    }
                </div>
                <div className={styles.images}>
                    <img src={nobreak} alt="NoBreak Intelbras" className={styles.nobreak} />
                    {seeMoreThird &&
                        <img src={estab} alt="Estabilizador Intelbras" className={styles.nobreak} />
                    }
                </div>
            </div>

            <div className={styles.services}>
                <div className={styles.text}>
                    <h2>Videoconferência</h2>
                    <h3>
                        Videoconferência é uma tecnologia que permite o contato visual e sonoro entre
                        pessoas que estão em lugares diferentes, dando a sensação de encontram-se no
                        mesmo local.
                        Nosso sistema disponibiliza o acesso a imagens em tempo real, troca de mensagem
                        (chat), transferência de arquivos e voz integrada.
                        Baseado em software, o meio de transmissão e feito atravez da Internet via
                        computador, tablet ou mobile.
                        Ótimo custo benefício, pois as licenças atendem tanto pequenas como grandes
                        empresas.
                    </h3>
                </div>
                <div className={styles.images}>
                    <img src={video} alt="Videoconferência" className={styles.video} />
                </div>
            </div>

            <div className={styles.services}>
                <div className={styles.text}>
                    <h2>Redes e Segurança</h2>
                    <h3>
                        A FCP é uma revenda autorizada da Intelbras e tem como parceira empresas certificadas
                        dos melhores fabricantes como: Fortinet, Aruba e Avaya. Através de parceria com
                        as empresas Opportunity e OppWare podendo oferecer a você e sua empresa todo suporte
                        técnico necessário para o desempenho de seu projeto, precificação e a escolha do melhor produto
                        para atender a sua necessidade dentro das suas condições financeiras.                        
                    </h3>
                </div>
                <div className={styles.images}>
                    <img src={redes} alt="Roteador e Switch" className={styles.video} />
                </div>
            </div>

            <div className={styles.services}>
                <div className={styles.text}>
                    <h2>Rede Estruturada</h2>
                    <h3>
                        O Sistema de Cabeamento Estruturado surgiu como resposta ao avanço das telecomunicações,
                        objetivando a criação de um padrão para o cabeamento instalado dentro de edifícios comerciais
                        e residenciais. Mais que fios ligando computadores, o cabeamento estruturado é a maneira mais
                        segura de preservar seu investimento.<p></p>
                    </h3>
                    {seeMoreFourth === false &&
                        <button className={styles.more}
                            onClick={handleSeeMoreFourth}
                            title='Ver mais'>
                            +
                        </button>
                    }
                    {seeMoreFourth &&
                        <h3>
                            Ele deve ser versátil para permitir mudanças e alterações de layout e garantir a redução de
                            problemas de interrupção, custos de manutenção e remanejamento de cabos.
                        </h3>
                    }
                    {seeMoreFourth &&
                        <h3>
                            O propósito de um sistema de cabeamento estruturado é prover uma base sólida para o bom
                            desempenho das redes de informações tecnológicas, visando a longevidade do sistema.
                            O cabeamento estruturado, têm uma importância muito grande pois a empresa poderá satisfazer suas
                            necessidades iniciais e futuras, se caso ela precisar acrescentar mais dispositivos conforme o
                            crescimento da empresa, no seu cabeamento isso será feito de maneira simples e fácil.
                        </h3>
                    }
                    {seeMoreFourth &&
                        <button className={styles.less}
                            onClick={handleSeeLessFourth}
                            title='Ver menos'>
                            -
                        </button>
                    }
                </div>
                <div className={styles.images}>
                    <img src={cabos} alt="Cabeamento organizado" />
                    {seeMoreFourth &&
                        <img src={cabosd} alt="Cabeamento organizado" className={styles.imgtwo} />
                    }
                </div>
            </div>

            <div className={styles.services}>
                <div className={styles.text}>
                    <h2>Desenvolvimento de sites</h2>
                    <h3>
                        Em parceria com a Peralta Solutions ofertamos também serviços de desenvolvimento de
                        sites personalizados para você e sua empresa. <p></p>
                        Desenvolvemos sites responsivos, com a qualidade e suporte técnico que você merece.
                        Desde portfólios para apresentar seu trabalho as pessoas até sites completos para que
                        sua empresa ganhe cada vez mais clientes e espaço no mercado.
                    </h3>
                </div>
                <div className={styles.images}>
                    <img src={site} alt="Roteador e Switch" className={styles.video} />
                </div>
            </div>

            <div className={styles.textcontainer}>
                <h1 className={styles.secondtext}>
                    Ficou interessado em algum serviço?
                    Entre em <NavLink to='/contato' className={styles.links}>contato</NavLink>
                    para solicitar um orçamento.
                </h1>
            </div>

            <Footer />
        </div>
    )
}

export default Services