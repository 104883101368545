import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Company from './pages/Company';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/empresa' element={<Company />} />
        <Route path='/servicos' element={<Services />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/contato' element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
