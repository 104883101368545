import styles from './Contact.module.css';

import NavBar from '../components/NavBar.js';
import Footer from '../components/Footer.js';

import emailjs from 'emailjs-com';
import { useState } from 'react';

import contato from './images/contato.jpg'
import whatsapp from './images/whatsapp.jpg';
import insta from './images/insta.jpg';
import mail from './images/mail.jpg';

const Contact = () => {

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const [name, setName] = useState('');
    const [nameLabel, setNameLabel] = useState(false);

    const [email, setEmail] = useState('');
    const [emailLabel, setEmailLabel] = useState(false);

    const [message, setMessage] = useState('');
    const [messageLabel, setMessageLabel] = useState(false);

    const handleNameLabel = () => { setNameLabel(true) }

    const handleEmailLabel = () => { setEmailLabel(true) }

    const handleMessageLabel = () => { setMessageLabel(true) }

    const handleSendMail = (e) => {

        e.preventDefault();

        setLoading(true);

        setSuccess(false);
        setError(false);

        var templateParams = {
            from_name: name,
            message: message,
            from_mail: email
        }

        emailjs.send('gmailservice', 'template_fcp', templateParams, 'jjW2IrdjTe7WphWVs')
            .then(function (response) {
                setSuccess(true);
                setLoading(false);
            }, function (error) {
                setError(true);
                setLoading(false);
            })

        setName('');
        setEmail('');
        setMessage('');
        setEmailLabel(false);
        setMessageLabel(false);
        setNameLabel(false);
    }

    return (
        <div>
            <NavBar stick={false} />
            <div className={styles.container}>
                <h1>Contate-nos</h1>
                <h2>Temos a solução ideal para você!</h2>
                <div className={styles.block}>
                    <div className={styles.firstbox}>
                        <img src={contato} alt="" />
                        <div className={styles.links}>
                            <a href='https://www.instagram.com/fcpservices_solucoes_integrada/' className={styles.instaref}>
                                <img src={insta} alt="Instagram" className={styles.insta} />
                            </a>
                            <a href='https://api.whatsapp.com/send?phone=5511961444586' className={styles.whatsref}>
                                <img src={whatsapp} alt="WhatsApp" className={styles.whats} />
                            </a>
                            <a href='mailto:atendimento@fcpservices.com.br' className={styles.mail}>
                                <img src={mail} alt="Email" className={styles.mail} />
                            </a>
                        </div>
                        <h3>CNPJ: 29.738.462/0001-92</h3>
                    </div>
                    <div className={styles.secondbox}>
                        <h3>SEJA VOCÊ TAMBÉM UM CLIENTE FCP!</h3>
                        <h4>Nos conte seu projeto, entraremos em contato o mais rápido possível.</h4>
                        <form onSubmit={handleSendMail} >
                            {nameLabel &&
                                <div className={styles.labelcontainer}>
                                    <label className={styles.label}>Nome</label>
                                </div>
                            }
                            <div className={styles.formcontainer}>
                                <input type='text'
                                    placeholder={nameLabel === false ? 'Seu nome *' : ''}
                                    onChange={(e) => setName(e.target.value)}
                                    onClick={handleNameLabel}
                                    value={name}
                                    required={true}
                                    className={styles.text} />
                            </div>
                            {emailLabel &&
                                <div className={styles.labelcontainer}>
                                    <label className={styles.label}>E-mail</label>
                                </div>
                            }
                            <div className={styles.formcontainer}>
                                <input type='email'
                                    placeholder={emailLabel === false ? 'Seu e-mail *' : ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onClick={handleEmailLabel}
                                    value={email}
                                    required={true}
                                    className={styles.text} />
                            </div>
                            {messageLabel &&
                                <div className={styles.labelcontainer}>
                                    <label className={styles.label}>Mensagem</label>
                                </div>
                            }
                            <div className={styles.formcontainer}>
                                <textarea
                                    placeholder={messageLabel === false ? 'Sua mensagem *' : ''}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onClick={handleMessageLabel}
                                    value={message}
                                    required={true}
                                    className={styles.textarea} />
                            </div>
                            {loading === false &&
                                <div className={styles.formcontainer}>
                                    <input type="submit"
                                        value="ENVIAR"
                                        className={styles.submit} />
                                </div>
                            }
                            {loading &&
                                <div className={styles.formcontainertwo}>
                                    <div class={styles.loader} />
                                </div>
                            }
                        </form>
                    </div >
                </div >
            </div >
            <Footer />
            {success && <div className={styles.success}>
                <h1>SOLICITAÇÃO ENVIADA!</h1>
            </div>}
            {error && <div className={styles.error}>
                <h1>ERRO! TENTE NOVAMENTE MAIS TARDE.</h1>
            </div>}
        </div >
    )
}

export default Contact