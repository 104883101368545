import styles from './Footer.module.css';

import React from 'react';

import { NavLink } from 'react-router-dom';

import intelbras from './images/intelbras.jpg';

const Footer = () => {

    return (
        <div className={styles.footer}>
            <div className={styles.textcontainer}>
                <div className={styles.firsttext}>
                    <h1>Desenvolva seu projeto</h1>
                    <h2>A FCP Services fornece diversas soluções para tornar o seu dia a dia
                        mais seguro e simples com serviçoes desde porteiro eletrônico a
                        manutenção de Nobreak.<span> </span>
                        <NavLink to='/servicos' className={styles.links}>Veja mais</NavLink>
                    </h2>
                </div>
                <div className={styles.secondtext}>
                    <h1>Projetos entregues</h1>
                    <h2>A FCP Services já tem diversos projetos entregues sempre com a maior
                        qualidade tornando a qualidade de vida de nossos clientes cada vez melhor.<span> </span>
                        <NavLink to='/portfolio' className={styles.links}>Veja nosso portfólio</NavLink>
                    </h2>
                </div>
                <div className={styles.thirdtext}>
                    <h1>Entre em contato</h1>
                    <h2>Ficou em dúvida quanto a algum serviço ou deseja solicitar um orçamento?
                        Entre em contato para que possamos fechar negócio!<span> </span>
                        <NavLink to='/contato' className={styles.links}>Contato</NavLink>
                    </h2>
                </div>
            </div>
            <div className={styles.linecontainer}>
                <div className={styles.line}>
                    <hr />
                </div>
            </div>
            <div className={styles.final}>
                <div className={styles.finalleft}>
                    <h3>FCP Services</h3>
                    <h4>Desenvolvido por Peralta Solutions.</h4>
                </div>
                <div className={styles.finalright}>
                    <img src={intelbras} alt="Revendedora autorizada IntelBras" />
                </div>
            </div>
        </div>
    )

}

export default Footer;