import styles from './NavBar.module.css';

import { NavLink } from 'react-router-dom';

import insta from './images/instagram.jpg';
import whats from './images/whats.jpg';
import phone from './images/phone.jpg';

const NavBar = ({stick, header}) => {

    return (
        <div className={'container'}>
            {stick === false && header === true && <div className={styles.head}>
                <div className={styles.first}>
                    <a href='https://www.instagram.com/fcpservices_solucoes_integrada/' className={styles.instaref}>
                        <img src={insta} alt="Instagram" className={styles.insta} />
                    </a>
                    <a href='https://api.whatsapp.com/send?phone=5511961444586' className={styles.whatsref}>
                        <img src={whats} alt="WhatsApp" className={styles.whats} />
                    </a>
                </div>
                <div className={styles.second}>
                    <a href='mailto:atendimento@fcpservices.com.br' className={styles.mail}>
                        <p>✉ atendimento@fcpservices.com.br</p>
                    </a>
                </div>
                <div className={styles.third}>
                    <img src={phone} alt="Telefone" className={styles.phone} />
                    <p className={styles.phonetxt}>(11) 96744-4586</p>
                </div>
            </div>}
            <div className={stick === false ? styles.nav : styles.navstick}>
                <div className={styles.navFirst}>
                    <NavLink to='/'
                        className={styles.logo}>
                        <span className={styles.fcp}>FCP<span className={styles.services}>Services</span></span>
                    </NavLink>
                </div>
                <div className={styles.navSecond}>
                    <NavLink to='/empresa'
                        className={styles.links}>
                        <span>Empresa</span>
                    </NavLink>
                    <NavLink to='/servicos'
                        className={styles.links}>
                        <span>Serviços</span>
                    </NavLink>
                    <NavLink to='/portfolio'
                        className={styles.links}>
                        <span>Portfólio</span>
                    </NavLink>
                    <NavLink to='/contato'
                        className={styles.links}>
                        <span>Contato</span>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default NavBar;