import styles from './Portfolio.module.css';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import portfolioone from './images/portfolioone.jpg';
import portfoliotwo from './images/portfoliotwo.jpg';
import portfoliothree from './images/portfoliothree.jpg';
import portfoliofour from './images/portfoliofour.jpg';
import whats from './images/whats.jpg'; 

const Portfolio = () => {

    const [stick, setStick] = useState(false);

    const handleScroll = () => {

        if (window.scrollY > 5) {
            setStick(true);
        } else {
            setStick(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={styles.container}>
            <NavBar stick={stick} header={true} />
            {stick &&
                <div>
                    <a href='https://api.whatsapp.com/send?phone=5511961444586' className={styles.whats}>
                        <img src={whats} alt='WhatsApp' />
                    </a>
                </div>
            }
            <div className={styles.textcontainer}>
                <h1>
                    Veja alguns dos projetos já entregues pela FCP Services!
                </h1>
            </div>
            
            <div className={styles.imagecontainer}>
                <img src={portfolioone} 
                alt="Instalação CFTV Wi-Fi. Instalação 100% limpa via wireless utilizando os roteadores TWIBI MASH da Intelbras e câmeras IC3." 
                className={styles.image}
                />
            </div>

            <div className={styles.imagecontainer}>
                <img src={portfoliotwo} 
                alt="Sistema CFTV IP com 64 câmeras VIP3220 e 5 NVD 3116P instalados no Rio de Janeiro. Software Sim Next Intelbras para monitoração." 
                className={styles.image}
                />
            </div>

            <div className={styles.imagecontainer}>
                <img src={portfoliothree} 
                alt="Instalação de câmera Wi-Fi com imagem Full HD, alarme de presença prográmavel por dia e hora, infravermelho, áudio bidirecional e gravação através de cartão MicroSD." 
                className={styles.image}
                />
            </div>

            <div className={styles.imagecontainer}>
                <img src={portfoliofour} 
                alt="Instalação de rádio e câmeras com comunicação via Wi-fi em torre para a empresa SOS Mata Atlântica." 
                className={styles.image}
                />
            </div>

            <div className={styles.textcontainer}>
                <h1 className={styles.secondtext}>
                    Seja você também um cliente FCP!
                    Entre em <NavLink to='/contato' className={styles.links}>contato</NavLink>
                    para solicitar um orçamento.
                </h1>
            </div>
            <Footer />
        </div>
    )
}

export default Portfolio