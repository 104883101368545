import styles from './Home.module.css';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

import slogan from './images/slogan.jpg';
import whats from './images/whats.jpg';
import partners1 from './images/partners1.jpg';
import partners2 from './images/partners2.jpg';
import partners3 from './images/partners3.jpg';
import partners4 from './images/partners4.jpg';
import partners5 from './images/partners5.jpg';
import partners6 from './images/partners6.jpg';
import partners7 from './images/partners7.jpg';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {

    const navigate = useNavigate();

    const [stick, setStick] = useState(false);
    const [clients, setClients] = useState(1);
    const [lastClient, setLastClient] = useState(0);

    const handleScroll = () => {

        if (window.scrollY > 5) {
            setStick(true);
        } else {
            setStick(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClick = () => {
        navigate('/servicos')
    }

    const handleNext = () => {
        setLastClient(clients);
        setClients(clients + 1);
    }

    const handleLast = () => {
        setLastClient(clients);
        setClients(clients - 1);
    }

    return (
        <div className={styles.container}>
            <NavBar stick={stick} header={true} />
            {stick &&
                <div>
                    <a href='https://api.whatsapp.com/send?phone=5511961444586' className={styles.whats}>
                        <img src={whats} alt='WhatsApp' />
                    </a>
                </div>
            }
            <img src={slogan} className={styles.slogan}
                alt='A segurança de sua família e de sua  empresa é o seu maior patrimônio!' />
            <h1>A FCPServices é uma empresa criada por profissionais da área de TI com mais de 25 anos de
                experiência. Nosso intuito é fornecer todo e qualquer tipo de suporte técnico aos nossos clientes,
                visando disponibilizar a opção de centralizar seus contratos de serviços de manutenção em uma 
                empresa, propondo um canal único de comunicação entre contratante e contratada. 
                Atingindo maior agilidade no atendimento, e menos burocracia e preocupação para quem 
                necessita da prestação de serviço.
            </h1>
            <div className={styles.orcamento}>
                <h2>NOSSOS SERVIÇOS</h2>
                <h3>____________________________</h3>
                <div className={styles.block}>
                    <div className={styles.list}>
                        <p className={styles.title}>PROJETOS</p>
                        <p>Elaboração de projetos especiais</p>
                        <p>Implementação de melhorias</p>
                        <p>Integração de soluções tecnológicas</p>
                        <button onClick={handleClick}>Ver mais</button>
                    </div>
                    <div className={styles.list}>
                        <p className={styles.title}>SUPORTE TÉCNICO</p>
                        <p>Redes de voz e dados</p>
                        <p>Sistemas de segurança eletronica</p>
                        <p>Nobreak, Estabilizadores e Rede Estruturada</p>
                        <button onClick={handleClick}>Ver mais</button>
                    </div>
                    <div className={styles.list}>
                        <p className={styles.title}>PARCERIAS TECNOLÓGICAS</p>
                        <p>Manutenção em redes estruturadas</p>
                        <p>Manutenção Nobreak</p>
                        <p>Manutenção em Redes de Voz e Dados</p>
                        <button onClick={handleClick}>Ver mais</button>
                    </div>
                </div>
            </div>
            <div className={styles.parceiros}>
                <h2>CLIENTES E PARCEIROS</h2>
                <h3>____________________________________</h3>
                {clients === 1 &&
                    <div className={styles.imgcontainer}>
                        <div className={styles.imagesback}>
                            <div>
                                <img src={partners1} alt='Parceiros e clientes' />
                            </div>
                        </div>
                        <button onClick={handleNext} className={styles.next}>→</button>
                    </div>}
                {clients === 2 &&
                    <div className={styles.imgcontainer}>
                        <button onClick={handleLast} className={styles.last}>←</button>
                        <div className={lastClient > clients ? styles.imagesback : styles.imagesfoard}>
                            <div>
                                <img src={partners2} alt='Parceiros e clientes' />
                            </div>
                        </div>
                        <button onClick={handleNext} className={styles.next}>→</button>
                    </div>}
                {clients === 3 &&
                    <div className={styles.imgcontainer}>
                        <button onClick={handleLast} className={styles.last}>←</button>
                        <div className={lastClient > clients ? styles.imagesback : styles.imagesfoard}>
                            <div>
                                <img src={partners3} alt='Parceiros e clientes' />
                            </div>
                        </div>
                        <button onClick={handleNext} className={styles.next}>→</button>
                    </div>}
                {clients === 4 &&
                    <div className={styles.imgcontainer}>
                        <button onClick={handleLast} className={styles.last}>←</button>
                        <div className={lastClient > clients ? styles.imagesback : styles.imagesfoard}>
                            <div>
                                <img src={partners4} alt='Parceiros e clientes' />
                            </div>
                        </div>
                        <button onClick={handleNext} className={styles.next}>→</button>
                    </div>}
                {clients === 5 &&
                    <div className={styles.imgcontainer}>
                        <button onClick={handleLast} className={styles.last}>←</button>
                        <div className={lastClient > clients ? styles.imagesback : styles.imagesfoard}>
                            <div>
                                <img src={partners5} alt='Parceiros e clientes' />
                            </div>
                        </div>
                        <button onClick={handleNext} className={styles.next}>→</button>
                    </div>}
                {clients === 6 &&
                    <div className={styles.imgcontainer}>
                        <button onClick={handleLast} className={styles.last}>←</button>
                        <div className={lastClient > clients ? styles.imagesback : styles.imagesfoard}>
                            <div>
                                <img src={partners6} alt='Parceiros e clientes' />
                            </div>
                        </div>
                        <button onClick={handleNext} className={styles.next}>→</button>
                    </div>}
                {clients === 7 &&
                    <div className={styles.imgcontainer}>
                        <button onClick={handleLast} className={styles.last}>←</button>
                        <div className={lastClient > clients ? styles.imagesback : styles.imagesfoard}>
                            <div>
                                <img src={partners7} alt='Parceiros e clientes' />
                            </div>
                        </div>
                    </div>}
            </div>
            <Footer />
        </div>
    )
}

export default Home;